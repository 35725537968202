import * as Sentry from '@sentry/browser';
import CryptoJS from 'crypto-js';
import { SECRET_PASS, NBN_TECH_HFC, NBN_TECH_FTTC, PROVIDER_NBN } from './constants';
import DataStore from './DataStore';
import {getAddressInfo} from "../src/services/rest/addressSearch"

export const pushEvent = (name, values = {}) => {
    let data = {};

    if (Object.keys(values).length > 0) {
        data = {...values, event: name}
    } else {
        data = {event: name};
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
};

export const pushGa4Event = (name, values = {}) => {
    pushEvent(name, values);
};

export const stripTags = value => {
    return value.replace(/(<([^>]+)>)/gi, "");
};

export const handleError = (error, errorInfo) => {
    console.error(error, errorInfo);
    return new Promise(resolve => {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureMessage(error);
            resolve(eventId);
        });
    });
};

export const serviceAddressParse = (address) => {
    const addressArray = address.split(' ')
    if (addressArray.length >= 6 && addressArray[addressArray.length - 1] !== '') {
        addressArray.slice(0, addressArray.length - 2).join(' ').split(',')[1].slice(1)
        const userPostcode = addressArray[addressArray.length - 1] || ''
        const userState = addressArray[addressArray.length - 2] || ''
        const userSuburbAndStreet = addressArray.slice(0, addressArray.length - 2).join(' ').split(',') || ''
        const userSuburb = userSuburbAndStreet.length > 1 ? userSuburbAndStreet[1].slice(1): ''
        const userStreet = userSuburbAndStreet.length > 0 ? userSuburbAndStreet[0].slice(1): ''

        return { userStreet, userPostcode, userSuburb, userState }
    }

    return {}
}

export const getAddressByLocationId = async locationId => {
    try {
        const parsedAddress = DataStore.getItem('parsedAddress')
        if (!parsedAddress) {
            const addressResponse = await getAddressInfo(locationId)
            if(addressResponse && addressResponse.length > 0) {
                const parsedResponse = {
                    userStreet: addressResponse[0].address.roadNumber1 + " "+ addressResponse[0].address.roadName + " " + addressResponse[0].address.roadTypeCode,
                    userPostcode: addressResponse[0].address.postcode, 
                    userSuburb: addressResponse[0].address.localityName, 
                    userState: addressResponse[0].address.stateTerritoryCode
                }
                DataStore.setItem('parsedAddress', JSON.stringify(parsedResponse))
                return parsedResponse
            }
        } 
        return parsedAddress
    }catch (exception) {
        return {error: "Something wrong with address!"}
    }
}

export const noWidows = text => {
    if (text) {
        return text.replace(/\s(?=[^\s]*$)/g, '&nbsp;')
    }
}

export const formatDecimals = (input, addDollarSign = false) => {
    const value = Number(String(input).replace('$', ''))
    const res = String(value).split('.')
    let result = ''

    if (res.length === 1 || (res.length > 1 && Number(res[1]) <= 0)) {
        result = String(res[0])
    } else if (res.length > 1 && (Number(res[1]) > 0 || res[1].length > 1)) {
        result = value.toFixed(2)
    } else {
        result = value.toFixed(2)
    }

    // In some edge cases 119.99999991 was still returning .00
    if (result.includes('.00')) {
        result = result.replace('.00', '')
    }

    return addDollarSign ? `$${result}` : result
};

export const decryptData = (text) => {
    const bytes = CryptoJS.AES.decrypt(text, SECRET_PASS)
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return data
}
export const checkForNTDEligibleAddress = (technology, serviceClass) => {
    const SERVICE_CLASSES = [23,24,33,34]
    if (!technology || !serviceClass) {
        return ''
    } else {
        if (technology.toLowerCase() === PROVIDER_NBN) {
            if (SERVICE_CLASSES.includes(serviceClass)) {
                if (serviceClass > 30) {
                    return NBN_TECH_FTTC
                } else {
                    return NBN_TECH_HFC
                }
            }
        }
        return ''
    }
}

export const convertStringToBool = (stringValue) => {return stringValue === 'true' ? true: stringValue === 'false' ? false: null}