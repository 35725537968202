import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./YesNoField.module.scss";
import InputLabel from "../InputLabel/InputLabel";
import { SIM_TYPE_3IN1, SIM_TYPE_ESIM } from "../../constants";

const YesNoField = (props) => {

    const [value, setValue] = useState(props.defaultValue);

    const handleChange = newValue => {
        setValue(newValue);
        props.setValue(props.name, newValue);
        props.trigger(props.name);
        if (newValue === SIM_TYPE_ESIM) {
            props.setShowModal(true);
        }
    };

    return (
        <div className={`${props.hasError ? styles.error : ''} mb-3`}>
            <InputLabel label={props.label} hasError={props.hasError} />
            <div className="d-flex">
                <div
                    className={`${styles.button} ${(value === true || value === 'true' || value === SIM_TYPE_ESIM) ? styles.active : ''}`}
                    style={{marginRight: '6px'}}
                    onClick={!props.simType ? () => handleChange(!props.yesValue ? true: props.yesValue) : () => handleChange(SIM_TYPE_ESIM)}
                >                    
                    {!props.simType ? "Yes" : "eSIM"}
                </div>
                <div
                    className={`${styles.button} ${(value === false || value === 'false' || value === SIM_TYPE_3IN1) ? styles.active : ''}`}
                    style={{marginLeft: '6px'}}
                    onClick={!props.simType ? () => handleChange(!props.noValue ? false : props.noValue) : () => handleChange(SIM_TYPE_3IN1)}
                >
                    {!props.simType ? "No" : "Physical"}
                </div>
            </div>
            <input
                type="hidden"
                defaultValue={props.defaultValue}
                {...props.register(props.name, { required: props.required })}
                value={value}
            />
        </div>
    );

};

YesNoField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    trigger: PropTypes.func.isRequired,
    required: PropTypes.bool,
    hasError: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default YesNoField;