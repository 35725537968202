import React, { useEffect, useContext, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputField from "../../components/InputField/InputField";
import CheckboxField from "../../components/CheckboxField/CheckboxField";
import Addressify from "../../components/Addressify/Addressify";
import YesNoField from "../../components/YesNoField/YesNoField";
import SelectField from "../../components/SelectField/SelectField";
import VerifyMobile from "../../components/VerifyMobile/VerifyMobile";
import Error from "../../components/Error/Error";
import { useForm } from "react-hook-form";
import OrderContext from "../../OrderContext";
import providers from "../../components/MobileForm/Providers";
import { pushEvent } from "../../globals";
import PropTypes from "prop-types";
import ReactModal from "../ReactModal/ReactModal";
import simData from '../../data/eSim.json';
import {SIM_TYPE_ESIM} from "../../constants";

const TYPE_PREPAID = "prePaid";
const TYPE_POSTPAID = "postPaid";

const MobileForm = (props) => {
    const { order } = useContext(OrderContext);
    const [showModal, setShowModal] = useState(false);
    const [eSimSelected, setESimSelected] = useState(null);
    const {
        trigger,
        formState: { errors },
    } = useForm();
    const watchHomeAddress = props.watch("serviceAddress");
    const watchKeepPhone = props.watch("portNumber");
    const watchPlanType = props.watch("currentProviderPlanType");
    const watchExistingMobile = props.watch("currentMobileNumber");

    useEffect(() => {
        pushEvent("connectionPageView");
    }, []);

    const heading = () => {
        return (
            <div className="text-center pt-3">
                <h1 className="bold m-0 font-xlarge">Is my device eSIM capable?</h1>
                <br />
                <p className="m-0">Excited to use eSIM? Check our most popular eSIM capable devices:</p>
            </div>
        )
    }

    const eSimBodyContent = () => {
        return (
            <Row className="mt-3 px-3 esim-body">
                    <Col lg={4}>
                        <p className="bold">Apple</p>
                        <ul className="m-0 mt-3 p-0 list-unstyled">
                            {simData.apple.map((item, index) => {
                                return (
                                    <li key={index} className="py-1">{item}</li>
                                )
                            })}
                        </ul>
                    </Col>
                    <Col lg={5}>
                        <p className="bold">Samsung</p>
                        <ul className="m-0 mt-3 p-0 list-unstyled">
                            {simData.samsung.map((item, index) => {
                                return (
                                    <li key={index} className="py-1">{item}</li>
                                )
                            })}
                        </ul>
                    </Col>
                    <Col lg={3}>
                        <p className="bold">Google</p>
                        <ul className="m-0 mt-3 p-0 list-unstyled">
                            {simData.google.map((item, index) => {
                                return (
                                    <li key={index} className="py-1">{item}</li>
                                )
                            })}
                        </ul>
                    </Col>
                    <Col className="mt-4">
                        <p className="bold m-0">Additional information:</p>
                        <p className="m-2 mx-0">If you can't find your device in this list, try checking with the manufacturer.</p>
                        <p className="mt-3"><span className="bold">Note: </span>Handsets purchased in China may not be eSIM capable.</p>
                    </Col>
                </Row>
        )
    }

    // useEffect(() => {
    //     // Form needs to be reset after an API call in order to update the values
    //     reset(order);
    // }, [order]);

    const handleCopyAddress = (isChecked) => {
        if (isChecked) {
            props.setValue("deliveryAddress", watchHomeAddress);
            trigger("deliveryAddress");
            if (props.setIsDeliveryAddressSelected) {
                props.setIsDeliveryAddressSelected(true)
            }
        } else {
            props.setValue("deliveryAddress", "");
        }
    };


    const handleSimType = (name, newValue) => {
        props.setValue(name, newValue);
        if (newValue === SIM_TYPE_ESIM) {
            props.handleEsimSelected && props.handleEsimSelected(true)
            setESimSelected(true)
        } else {
            props.handleEsimSelected && props.handleEsimSelected(false)
            setESimSelected(false)
        }
    }

    const getProviders = () => {
        let data = [];
        providers.forEach((item) => {
            data.push({ title: item, value: item });
        });
        return data;
    };

    return (
        <>
            {!props.isBroadbandForm ?
                <>
                    <Addressify
                        label="Home address"
                        name="serviceAddress"
                        hasError={!!props.errors.serviceAddress}
                        defaultValue={order.serviceAddress}
                        register={props.register}
                        setValue={props.setValue}
                        required={true}
                        setIsAddressSelected={props.setIsServiceAddressSelected || (() => {})}
                    />
                    <InputField
                        label="Full name"
                        subLabel="(Person accepting delivery)"
                        name="deliveryName"
                        hasError={!!props.errors.deliveryName}
                        defaultValue={order.deliveryName}
                        register={props.register}
                        required={true}
                    />
                    <InputField
                        label="Company name"
                        subLabel="(if applicable)"
                        name="company"
                        hasError={!!props.errors.company}
                        defaultValue={order.company}
                        register={props.register}
                        required={false}
                    />
                </>
            : ''}
            <Row className="mb-3">
                <Col>
                    {props.isBroadbandForm ? '' : <h2 className="m-0 mb-3">Enter your phone details:</h2>}
                    <YesNoField
                        label="Do you want an eSIM or a physical SIM"
                        name="simType"
                        hasError={!!props.errors.simType}
                        defaultValue={order.simType}
                        register={props.register}
                        setValue={handleSimType}
                        trigger={trigger}
                        simType={true}
                        setShowModal={setShowModal}
                        required={true}
                    />
                    {eSimSelected!=null && !eSimSelected && !props.isBroadbandForm &&
                        <>
                            <Addressify
                                label="Delivery address"
                                name="deliveryAddress"
                                hasError={!!props.errors.deliveryAddress}
                                defaultValue={order.deliveryAddress}
                                register={props.register}
                                setValue={props.setValue}
                                required={true}
                                setIsAddressSelected={props.setIsDeliveryAddressSelected || (() => {})}
                            />

                            <div style={{marginTop: "-0.5rem"}}>
                                <CheckboxField
                                    name="copyHomeAddress"
                                    hasError={!!props.errors.copyHomeAddress}
                                    defaultValue={order.copyHomeAddress}
                                    register={props.register}
                                    handleClick={handleCopyAddress}
                                    required={false}
                                    trigger={trigger}
                                    setValue={props.setValue}
                                    style={{alignItems: "center"}}
                                >
                                    <span className="bold">Same as home address</span>
                                </CheckboxField>
                            </div>
                        </>
                    }
                <YesNoField
                    label="Do you want to keep your current phone number"
                    name="portNumber"
                    hasError={!!props.errors.portNumber}
                    defaultValue={order.portNumber}
                    register={props.register}
                    setValue={props.setValue}
                    trigger={trigger}
                    required={true}
                    noValue="false"
                    yesValue="true"
                    />
                    {watchKeepPhone === true || watchKeepPhone === 'true' ? (
                        <React.Fragment>
                            <InputField
                                label="Your mobile number"
                                name="currentMobileNumber"
                                hasError={!!props.errors.currentMobileNumber}
                                defaultValue={order.currentMobileNumber}
                                register={props.register}
                                required={watchKeepPhone === true}
                                type="mobile"
                                placeholder="0422222222"
                                control={props.control}
                            />
                            <VerifyMobile handleVerified={() => props.setMobileIsVerified(true)} mobile={watchExistingMobile} resetMobileVerification={props.resetMobileVerification ? props.resetMobileVerification: false } />
                            <SelectField
                                label="Your current provider"
                                name="currentProvider"
                                hasError={!!props.errors.currentProvider}
                                defaultValue={order.currentProvider}
                                register={props.register}
                                required={watchKeepPhone === true || watchKeepPhone === 'true'}
                                items={getProviders()}
                                placeholder="Select provider"
                            />
                            <Row>
                                <Col lg={6}>
                                    <SelectField
                                        label="Your current plan type"
                                        name="currentProviderPlanType"
                                        hasError={!!props.errors.currentProviderPlanType}
                                        defaultValue={order.currentProviderPlanType}
                                        register={props.register}
                                        required={watchKeepPhone === true || watchKeepPhone === 'true'}
                                        items={[
                                            { title: "Prepaid", value: TYPE_PREPAID },
                                            { title: "On a plan", value: TYPE_POSTPAID },
                                        ]}
                                        placeholder="Select current plan type"
                                    />
                                </Col>
                                <Col lg={6}>
                                    {watchPlanType === TYPE_PREPAID ? (
                                        <InputField
                                            label="Date of birth"
                                            subLabel="DD/MM/YYYY"
                                            name="currentAccountDob"
                                            hasError={!!props.errors.currentAccountDob}
                                            defaultValue={order.currentAccountDob ? order.currentAccountDob : order.dob}
                                            register={props.register}
                                            required={watchPlanType === TYPE_PREPAID}
                                            placeholder="DD/MM/YYYY"
                                            type="dob"
                                            control={props.control}
                                        />
                                    ) : (
                                        <InputField
                                            label="Account number"
                                            name="currentProviderNumber"
                                            hasError={!!props.errors.currentProviderNumber}
                                            defaultValue={order.currentProviderNumber}
                                            register={props.register}
                                            required={watchKeepPhone === true || watchKeepPhone === 'true'}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </React.Fragment>
                    ) : ''}
                    {watchKeepPhone === false || watchKeepPhone === 'false' ? (
                        <SelectField
                            label="Choose your new number"
                            name="newMobileNumber"
                            hasError={!!props.errors.newMobileNumber}
                            defaultValue={props.availableNumbers ? props.availableNumbers[0] : ""}
                            register={props.register}
                            required={watchKeepPhone === false || watchKeepPhone === 'false'}
                            items={props.availableNumbers}
                        />
                    ) : ''}
                </Col>
            </Row>
            {props.validationErrors ? (
                <Row className="mb-4">
                    <Col>
                        <Error errors={props.validationErrors} />
                    </Col>
                </Row>
            ) : (
                ""
            )}
            <ReactModal show={showModal} onHide={() => setShowModal(false)} heading={heading()}>
                {eSimBodyContent()}
            </ReactModal>
        </>
    );
};

MobileForm.propTypes = {
    errors: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    isBroadbandForm: PropTypes.bool
};

export default MobileForm;
