import React, { useCallback, useContext, useEffect, useState, useMemo } from "react";
import styles from "./ConnectionBroadband.module.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OrderContext from "../../OrderContext";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import InputField from "../../components/InputField/InputField";
import CheckboxField from "../../components/CheckboxField/CheckboxField";
import Addressify from "../../components/Addressify/Addressify";
import ReadOnlyInputField from "../../components/ReadOnlyInputField/ReadOnlyInputField";
import YesNoField from "../../components/YesNoField/YesNoField";
import BackNextButtons from "../../components/BackNextButtons/BackNextButtons";
import { FTTP_HFC_SERVICE_CLASSES, SIM_TYPE_3IN1, SERVICE_CLASSES } from "../../constants";
import LoadingOverlay from "../../layout/LoadingOverlay/LoadingOverlay";
import {checkForNTDEligibleAddress, handleError, pushEvent, pushGa4Event, convertStringToBool} from "../../globals";
import {sha256} from "js-sha256";
import MobileForm from "../../components/MobileForm/MobileForm";
import Error from "../../components/Error/Error";
import YesNoFieldV2 from "../../components/YesNoField/v2/YesNoFieldV2";
import ReactModal from "../../components/ReactModal/ReactModal";
import NTDData from "../../data/ntd.json"
import ntdFTTCImage from "../../images/ntd-fttc.webp"
import ntdHFCImage from "../../images/ntd-hfc.webp"
import SelectFieldAutocomplete from "../../components/SelectField/SelectFieldAutocomplete";
import { getRspLists } from "../../services/rest/getRspLists";
import DataStore from "../../DataStore";
import {connectionSubmitEvent, addShippingInfoConnectionPageEvent} from "../../events"

const ConnectionBroadband = (props) => {
    const { orderType, order, updateOrder, availableNumbers, isLoggedIn } = useContext(OrderContext);
    const {
        register,
        watch,
        handleSubmit,
        control,
        setValue,
        trigger,
        reset,
        formState: { errors },
    } = useForm();
    const watchPhoneLine = watch("newPhoneRequired");
    const watchHasActivePhone = watch("hasActivePhoneLine");
    const watchTransferVoip = watch("transferPstn");
    const watchServiceNumber = watch("serviceNumber");
    const watchActivationDate = watch("checkActivation");
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState(null);
    const [mobileIsVerified, setMobileIsVerified] = useState(order.mobileIsVerified);
    const [orderNtd, setOrderNtd] = useState(order.orderNtd)
    const [showModal, setShowModal] = useState(false)
    const [eSimSelected, setEsimSelected] = useState(true)

    const checkForNTDEligibility = useMemo(() => checkForNTDEligibleAddress(order.technology, order.serviceClass), [order])

    const [isAddressSelected, setIsAddressSelected] = useState(true)
    const [selectedProviderOption, setSelectedProviderOption] = useState(null);
    const [nbnProviders, setNbnProviders] = useState([])
    const [resetMobileVerification, setResetMobileVerification] = useState(false)

    useEffect(() => {
        pushEvent("connectionPageView");
        if (checkForRspOption()) {
            getNbnProviders()
        }
    }, []);

    const getNbnProviders =  useCallback(async() => {
        const providers = DataStore.getItem("NbnProviders")
         if(providers) {
            setNbnProviders(providers)
         } else {
            const providers = await getRspLists()
            DataStore.setItem('NbnProviders', providers)
            setNbnProviders(providers)
         }
        }, [])

    useEffect(() => {
        // Form needs to be reset after an API call in order to update the values
        reset(order);
        setValue("deliveryAddress", order.serviceAddress);
        trigger("deliveryAddress");
    }, [order]);


    useEffect(() => {
        if (watchPhoneLine === false) {
            setValue("transferPstn", null);
        }
    }, [watchPhoneLine]);

    const handleSubmitEvent = (data) => {
        if (data.deliveryAddress && (data.deliveryAddress !== data.serviceAddress)) {
            if (orderNtd || !eSimSelected || checkForDevicesOrder) {
                if (!isAddressSelected) {
                    setValidationErrors("Please select a valid delivery address from the options provided.")
                    return
                }
            }
        }
        if(checkForRspOption()) {
            if(!selectedProviderOption) {
                setValidationErrors("Please select your previous nbn provider from the options provided, or “Any other” if unknown.")
                setSelectedProviderOption(null)
                return
            }
            data.previousProvider = selectedProviderOption?.label
            data.rsp_id = selectedProviderOption?.value
            data.isTransfer = true
        }

        data.portNumber = convertStringToBool(data.portNumber) //typecast the string to boolean
        if(data.portNumber) {
            const verifiedMobileNumber = DataStore.getItem('verifiedMobileNumber') ? DataStore.getItem('verifiedMobileNumber'): ''
            if (verifiedMobileNumber.length > 0) {
                if(data.currentMobileNumber !== verifiedMobileNumber) {
                    setResetMobileVerification(true)
                    setValidationErrors("Your existing mobile number needs to be verified by sending a code above.");
                    setIsLoading(false);
                    return
                }
            } else {
                setResetMobileVerification(true)
                setValidationErrors("Your existing mobile number needs to be verified by sending a code above.");
                return
            }
        } 

        data.completed = false
        data.validateService = true
        setValidationErrors(null)
        setIsLoading(true)
        data.page = 'Connection broadband'
        updateOrder(data)
            .then(() => {
                connectionSubmitEvent(order)
                addShippingInfoConnectionPageEvent(orderType, order)
                pushJourneyEvent(data)
                history.push("/payment")
            })
            .catch((error) => {
                if (error.response.status === 422 || error.response.status === 403) {
                    // Show validation error
                    if (error.response.data && error.response.data) {
                        setValidationErrors(error.response.data.errors);
                    } else {
                        setValidationErrors("Oops! It appears something went wrong, please try again.");
                    }
                } else {
                    //Some other error occurred, log and show error screen
                    setValidationErrors("Oops! It appears something went wrong, please try again.");
                    handleError("Error submitting broadband connection details in handleSubmitEvent.", error.response);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCopyAddress = (isChecked) => {
        if (isChecked) {
            setValue("deliveryAddress", order.serviceAddress);
            trigger("deliveryAddress");
            setIsAddressSelected(true)
        } else {
            setValue("deliveryAddress", "");
        }
    };

    const pushJourneyEvent = data => {
        pushGa4Event('journeyStep', {
            journeyName: 'NBN Plans',
            stepName: 'Connection',
            stepNo: 7,
            userPostcode: '', //todo Andy adding these to the API response
            userSuburb: '',  //todo Andy adding these to the API response
            userState: '', //todo Andy adding these to the API response
            company: data.company,
            hashedName: sha256(`${data.firstName} ${data.lastName}`),
            hashedNumber: sha256(data.contact)
        });
    };

    const isFttpOrHfc = () => {
        return FTTP_HFC_SERVICE_CLASSES.includes(parseInt(order.serviceClass));
    };

    const FttpHfcFields = () => {
        return (
            <React.Fragment>
                <YesNoField
                    label="Does your address have a phone number you would like to keep"
                    name="newPhoneRequired"
                    hasError={!!errors.newPhoneRequired}
                    defaultValue={order.newPhoneRequired}
                    register={register}
                    setValue={setValue}
                    trigger={trigger}
                />
                {watchPhoneLine === true ? (
                    <React.Fragment>
                        <InputField
                            label="Your phone number"
                            subLabel="(The fixed line at your address)"
                            name="serviceNumber"
                            hasError={!!errors.serviceNumber}
                            defaultValue={order.serviceNumber}
                            register={register}
                            required={watchPhoneLine === true}
                            type="voip"
                            control={control}
                        />
                        <YesNoField
                            label="Do you want to transfer this number as your Exetel Home phone (VoIP) number?"
                            name="transferPstn"
                            hasError={!!errors.transferPstn}
                            defaultValue={order.transferPstn}
                            register={register}
                            setValue={setValue}
                            trigger={trigger}
                        />
                        {watchTransferVoip === true ? (
                            <InputField
                                label="Account number"
                                subLabel="(with your current provider)"
                                name="currentPhoneProviderAccountNumber"
                                hasError={!!errors.currentPhoneProviderAccountNumber}
                                defaultValue={order.currentPhoneProviderAccountNumber}
                                register={register}
                                required={watchTransferVoip === true}
                            />
                        ) : (
                            ""
                        )}
                    </React.Fragment>
                ) : (
                    ""
                )}
            </React.Fragment>
        );
    };

    const NonHfcFields = () => {
        return (
            <React.Fragment>
                <YesNoField
                    label="Does your premises have an active phone line?"
                    name="hasActivePhoneLine"
                    hasError={!!errors.hasActivePhoneLine}
                    defaultValue={order.hasActivePhoneLine}
                    register={register}
                    setValue={setValue}
                    trigger={trigger}
                />
                {watchHasActivePhone === false ? (
                    <React.Fragment>
                        <CheckboxField
                            name="phoneBundleConditionsAgreed"
                            hasError={!!errors.phoneBundleConditionsAgreed}
                            defaultValue={order.phoneBundleConditionsAgreed}
                            register={register}
                            setValue={setValue}
                            trigger={trigger}
                            required={true}
                        >
                            I understand I need a new phone line installed to connect this service at a cost of $297 plus labour
                            and materials.
                        </CheckboxField>
                    </React.Fragment>
                ) : (
                    ""
                )}
                {watchHasActivePhone === true ? (
                    <React.Fragment>
                        <InputField
                            label="Your phone number"
                            subLabel="(optional)"
                            name="serviceNumber"
                            hasError={!!errors.serviceNumber}
                            defaultValue={order.serviceNumber}
                            register={register}
                            required={watchPhoneLine === true}
                            type="voip"
                            control={control}
                        />
                        {watchServiceNumber ? (
                            <React.Fragment>
                                <YesNoField
                                    label="Do you want to transfer this number as your Exetel Home phone (VoIP) number?"
                                    name="transferPstn"
                                    hasError={!!errors.transferPstn}
                                    defaultValue={order.transferPstn}
                                    register={register}
                                    setValue={setValue}
                                    trigger={trigger}
                                />
                                {watchTransferVoip === true ? (
                                    <InputField
                                        label="Account number"
                                        subLabel="(with your current provider)"
                                        name="currentPhoneProviderAccountNumber"
                                        hasError={!!errors.currentPhoneProviderAccountNumber}
                                        defaultValue={order.currentPhoneProviderAccountNumber}
                                        register={register}
                                        required={watchTransferVoip === true}
                                    />
                                ) : (
                                    ""
                                )}
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                    </React.Fragment>
                ) : (
                    ""
                )}
            </React.Fragment>
        );
    };

    const handleNtdOrder = (value, name) => {
        setOrderNtd(value === 1 ? true: false)
        setValue(name, value)
    }

    const handleEsimSelected = (isSelected) => {
        setEsimSelected(isSelected)
    }

    const getURL = () => {

        const mid = SERVICE_CLASSES.length / 2

        if (order.serviceClass <= SERVICE_CLASSES[mid-1]) {
            return 'https://www.nbnco.com.au/learn/network-technology/hybrid-fibre-coaxial-explained-hfc-3'

        } else if (order.serviceClass > SERVICE_CLASSES[mid-1]) {
            return 'https://www.nbnco.com.au/learn/network-technology/fibre-to-the-curb-explained-fttc'
        }

        return ''
    }

    const switchNotification = () =>{
        return (
            <div className='mb-2'>
                <p>
                    Please ensure that you have the nbn Co supplied device available and switched on before the expected activation date.
                    This is important otherwise there will be a delayed activation for your service.
                </p>
                <p className='mb-1'>More information can be found here:</p>
                <a className='mb-3' href={getURL()} target='__blank'>
                    { getURL() }
                </a>
            </div>
        )
    }


    const DeliveryAddressSection = (children) => {
        return (
            <Row className={`${styles.content} mb-4`}>
                <Col>
                    <h2 className="m-0 mb-3">Delivery details:</h2>
                    <Addressify
                        label="Delivery address"
                        subLabel="(Please supply a physical address as we cannot deliver to a PO box)"
                        name="deliveryAddress"
                        hasError={!!errors.deliveryAddress}
                        defaultValue={order.serviceAddress}
                        register={register}
                        setValue={setValue}
                        setIsAddressSelected={setIsAddressSelected}
                    />
                    {/* todo: Tidy up this styling */}
                    <div style={{ marginTop: "-0.5rem" }}>
                        <CheckboxField
                            name="copyInstallationAddress"
                            hasError={!!errors.copyInstallationAddress}
                            defaultValue={true}
                            register={register}
                            handleClick={handleCopyAddress}
                            required={false}
                            trigger={trigger}
                            setValue={setValue}
                            style={{ alignItems: "center" }}
                        >
                            <span className="bold">Same as service address</span>
                        </CheckboxField>
                    </div>
                    <InputField
                        label="Full name"
                        subLabel="(Person accepting delivery)"
                        name="deliveryName"
                        hasError={!!errors.deliveryName}
                        defaultValue={
                            order.deliveryName ? order.deliveryName : `${order.firstName} ${order.lastName}`
                        }
                        register={register}
                        required={true}
                    />
                    <InputField
                        label="Company name"
                        subLabel="(if applicable)"
                        name="company"
                        hasError={!!errors.company}
                        defaultValue={order.company}
                        register={register}
                        required={false}
                    />
                </Col>
                {children && children}
            </Row>
        )
    }

    const checkForDevicesOrder = useMemo(() => {
        return order.upfrontModem || order.eeroModem || order.extenderModem > 0
    }, [order])

    const checkForRspOption = useCallback(() => {
        const checkActiveService = order.activeService && order.activeService > 0
       if (isLoggedIn) {
         return !order.isFlip ? checkActiveService: false
       }
       return checkActiveService
    }, [order, isLoggedIn])

    return (
        <div className={styles.container}>
            <LoadingOverlay isLoading={isLoading}>
                        <form onSubmit={handleSubmit(handleSubmitEvent)}>
                            {/** service address details */}
                            <Row className={`${styles.content} mb-4`}>
                                <Col>
                                    <p className="m-0 mb-2 d-lg-none">
                                        <Link to="/">
                                            <span className="font-smaller color-indigo underline bold">&lt; Back to Account</span>
                                        </Link>
                                    </p>
                                    <h1 className="m-0 mb-4 mb-lg-3">Connection details</h1>
                                    <div className={`${styles.separator} d-none d-lg-block`} />
                                    <div className="mb-4">
                                        <ReadOnlyInputField label="Service address" value={order.serviceAddress} />
                                        {order.provider === "OptiComm" ? (
                                            <InputField
                                                label="Lot number"
                                                subLabel=""
                                                name="lotNumber"
                                                hasError={!!errors.lotNumber}
                                                defaultValue={order.lotNumber}
                                                register={register}
                                                required={false}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    {/** RSP select autocomplete section */}
                                    {checkForRspOption() ?
                                        <div className="mb-4">
                                            <p>So we can best manage the transfer, please advise the current NBN provider at this address.</p>
                                            <SelectFieldAutocomplete
                                                label="NBN Provider"
                                                name="previousProvider"
                                                hasError={!(selectedProviderOption !== null ? Object.keys(selectedProviderOption).length > 0: true)}
                                                defaultValue={order.previousProvider}
                                                register={register}
                                                required={true}
                                                items={nbnProviders}
                                                placeholder="--Select--"
                                                onChange={setSelectedProviderOption}
                                            />
                                        </div>: 
                                    ''}
                                     {/** RSP select autocomplete section */}

                                    <div className="mb-4">
                                        <h2 className="m-0 mb-3">Service activation date:</h2>
                                        {!watchActivationDate ? (
                                            <InputField
                                                label="Preferred activation date"
                                                subLabel="DD/MM/YYYY"
                                                name="expectedInstallationDate"
                                                placeholder="DD/MM/YYYY (max. 30 days from now)"
                                                hasError={!!errors.expectedInstallationDate}
                                                defaultValue={order.expectedInstallationDate}
                                                register={register}
                                                required={false}
                                                type="dob"
                                                control={control}
                                            />
                                        ) : (
                                            <ReadOnlyInputField
                                                label="Preferred activation date"
                                                subLabel="DD/MM/YYYY"
                                                value="ASAP"
                                            />
                                        )}

                                        {/* todo: Tidy up this styling */}
                                        <div style={{ marginTop: "-0.5rem" }}>
                                            <CheckboxField
                                                name="checkActivation"
                                                hasError={!!errors.checkActivation}
                                                defaultValue={order.checkActivation}
                                                register={register}
                                                required={false}
                                                trigger={trigger}
                                                setValue={setValue}
                                                style={{ alignItems: "center" }}
                                            >
                                                <span className="bold">As soon as possible</span>
                                            </CheckboxField>
                                            {order.isBusiness ? <p className="m-0 font-small">(nbn™ Enterprise Ethernet orders typically take 50 business days to activate)</p> : ''}
                                        </div>
                                    </div>

                                    {/** Modal to give NTD info */}
                                    <ReactModal
                                        show={showModal}
                                        contentClassName="p-0" headerClassName="p-0"
                                        closeVariant="white"
                                        onHide={() => setShowModal(false)}
                                        heading={<img src={checkForNTDEligibility ==="FTTC" ? ntdFTTCImage: ntdHFCImage} alt="NTD icon" width={'100%'} />}
                                    >
                                    <div className="px-5 py-3">
                                        <h1 className="bold m-0 font-xlarge">{NTDData.ntdHeadingModal}</h1>
                                        <div className="pt-3">
                                            {NTDData.ntdAboudModal && NTDData.ntdAboudModal.length > 0 ?
                                                    NTDData.ntdAboudModal.map((item) => {
                                                        return (
                                                            <p>{item}</p>
                                                        )
                                                    })
                                                : ''}
                                        </div>
                                    </div>
                                    </ReactModal>
                                    {/** Modal to give NTD info */}

                                    {/** switch notification section */}
                                    {
                                       order.serviceClass && SERVICE_CLASSES.includes(order.serviceClass) ?
                                           switchNotification() : ''
                                    }
                                    {/** switch notification section */}

                                    {/** NTD ordering option */}
                                    {(checkForNTDEligibility && !order.isFlip) ? <div className="pt-5">
                                            <p className="bold">{NTDData.ntdOptionHeading}</p>
                                            <p>{NTDData.ntdOptionDesc}</p>
                                            <p className={`${errors.orderNtd ? 'color-red': ''} bold underline`} role="button" onClick={() => setShowModal(true)}>What is this? {`>`}</p>
                                            <div className="pt-1">
                                                <YesNoFieldV2 classes="d-flex" value={orderNtd} hasError={!!errors.orderNtd}>
                                                    <YesNoFieldV2.NoButton value={orderNtd}  handleChange={() => handleNtdOrder(0, 'orderNtd')}>Yes, already have an NTD</YesNoFieldV2.NoButton>
                                                    <YesNoFieldV2.YesButton classNames="ms-2" value={orderNtd}  handleChange={() => handleNtdOrder(1, 'orderNtd')}>No, need to order an NTD</YesNoFieldV2.YesButton>
                                                    <input
                                                        type="hidden"
                                                        name="orderNtd"
                                                        {...register('orderNtd', { required: true })}
                                                        value={orderNtd}
                                                    />
                                                </YesNoFieldV2>
                                            </div>
                                            {orderNtd > 0 && <p className="pt-1">{NTDData.ntdDispatchInfo}</p>}
                                        </div>: ''}
                                    {/** NTD ordering option */}
                                </Col>
                            </Row>
                            {/** service address details */}

                            {/** delivery address details */}
                            {((checkForDevicesOrder || orderNtd) && (!order.mobileBundle)) ? DeliveryAddressSection(): ''}
                            {/** delivery address details */}

                            <Row className={styles.content}>
                                {/** phone service details */}
                                {!order.isBusiness ?
                                    <Row className="mb-3">
                                        <Col>
                                            <h2 className="m-0 mb-3">Enter your phone details:</h2>
                                            {isFttpOrHfc() ? FttpHfcFields() : NonHfcFields()}
                                        </Col>
                                    </Row>
                                : ''}
                                {/** phone service details */}

                                {!order.mobileBundle && validationErrors ? (
                                    <Row className="mb-4">
                                        <Col>
                                            <Error errors={validationErrors} />
                                        </Col>
                                    </Row>
                                ) : ''}

                                {/** mobile order option */}
                                {order.mobileBundle === true || order.mobileBundle === 1 ? (
                                    <Row className="mb-4">
                                        <Col>
                                            <h2 className="m-0 mb-3">ExeSIM mobile service details:</h2>
                                            <MobileForm
                                                register={register}
                                                errors={errors}
                                                control={control}
                                                setValue={setValue}
                                                watch={watch}
                                                setMobileIsVerified={setMobileIsVerified}
                                                mobileIsVerified={mobileIsVerified}
                                                validationErrors={validationErrors}
                                                availableNumbers={availableNumbers}
                                                isBroadbandForm={true}
                                                handleEsimSelected={handleEsimSelected}
                                                resetMobileVerification={resetMobileVerification}
                                            />
                                        </Col>
                                    </Row>
                                ) : ''}
                                {/** mobile order option */}
                                {(!order.mobileBundle || (order.mobileBundle && eSimSelected && (!checkForDevicesOrder && !orderNtd))) && <BackNextButtons
                                    handleSubmit={handleSubmit(handleSubmitEvent)}
                                    handleBack={() => history.push("/")}
                                />}
                            </Row>
                            {/** when mobile bundle order is there the delivery address field order changes */}
                            { ((order.mobileBundle && !eSimSelected) || (order.mobileBundle && (checkForDevicesOrder || orderNtd))) ? <div className="mt-4" >{DeliveryAddressSection(<BackNextButtons
                                    handleSubmit={handleSubmit(handleSubmitEvent)}
                                    handleBack={() => history.push("/")}
                                    className="mt-2"
                                />)}</div>: ''}
                            {/** when mobile bundle order is there the delivery address field order changes */}
                        </form>
            </LoadingOverlay>
        </div>
    );
};

ConnectionBroadband.propTypes = {};

export default ConnectionBroadband;